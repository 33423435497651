/**
 * Insert a hidden input field after the specified element.
 *
 * @param {any|jQuery|HTMLElement} element the element to insert after
 * @param {string} name the name of the hidden input field
 * @param {string} value the value to set
 * @param {string} dataGroupSelector the data attribute group selector
 * @returns {any|jQuery|HTMLElement} the inserted hidden input field
 */
export function insertHiddenField(element, name, value, dataGroupSelector = "") {
    const hiddenField = $("<input>", {
        id: name,
        type: "hidden",
        name: name,
        value: value,
    });
    if (dataGroupSelector) {
        hiddenField.attr("data-" + dataGroupSelector, "true");
    }
    element.after(hiddenField);
    return hiddenField;
}

/**
 * Remove hidden input fields related to a specific group.
 *
 * @param {any|jQuery|HTMLElement} element the reference element
 * @param {string} dataGroupSelector the data attribute group selector
 */
export function removeHiddenGroupFields(element, dataGroupSelector) {
    element.siblings(`input[data-${dataGroupSelector}='true']`).remove();
}

/**
 * Get the value of a hidden input field.
 *
 * @param {string} name the name of the hidden input field
 * @returns {string} the value of the hidden input field
 */
export function getHiddenFieldValue(name) {
    return $(`input[name="${name}"]`).val();
}
